<!-- 等级编辑弹窗 -->
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<template>
  <el-dialog
    :title="isUpdate?'修改背景图':'添加背景图'"
    :visible="visible"
    width="600px"
    :destroy-on-close="true"
    :lock-scroll="false"
    @update:visible="updateVisible">
    <el-tooltip class="item" effect="dark" content="Top Left 提示文字" placement="top-start">
      <p style="margin: -10px 0px 16px 27px;color: red">*  标识用来配置眼动轨迹图</p>
    </el-tooltip>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="82px">
      <el-form-item
        label="标题:"
        prop="roleName">
        <el-input
          v-model="form.name"
          placeholder="请输入标题"
          clearable/>
      </el-form-item>
      <el-form-item
              label="轨迹:"
              prop="flag">
        <el-select v-model="form.flag" placeholder="请选择轨迹" clearable>
          <el-option
                  :span="18"
                  v-for="item in trajectory"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <!--<el-form-item label="等级" prop="level_id">
        <el-select
                :span="18"
                v-model="form.current"
                placeholder="请选择等级"
                clearable
                multiple
        >
          <el-option
                  :span="18"
                  v-for="item in roleList"
                  :label="item.name"
                  :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>-->
      <el-row :gutter="15">
        <el-col :sm="22">
          <el-form-item
            label="背景:"
            prop="image">
            <!--            {{$baseImgUrl + form.image}}-->
            <el-upload
              class="avatar-uploader"
              :action="$http.defaults.baseURL+'upload/image/level_upload/image'"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="form.image" :src="$baseImgUrl + form.image"  v-model="form.image" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

        </el-col>
      </el-row>
      <el-row :gutter="15">
        <el-col :sm="22">
          <el-form-item
                  label="前测背景:"
                  prop="before_image">
            <!--            {{$baseImgUrl + form.image}}-->
            <el-upload
                    class="avatar-uploader"
                    :action="$http.defaults.baseURL+'upload/image/level_upload/image'"
                    :show-file-list="false"
                    :on-success="before_handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
            >
              <img v-if="form.before_image" :src="$baseImgUrl + form.before_image"  v-model="form.before_image" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

        </el-col>
      </el-row>
      <el-form-item label="备注:">
        <el-input
          v-model="form.remark"
          placeholder="请输入备注"
          :rows="4"
          type="textarea"/>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="updateVisible(false)">取消</el-button>
      <el-button
        type="primary"
        @click="save"
        :loading="loading">保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'LevelEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 表单验证规则
      rules: {
        name: [
          {required: true, message: '请输入等级', trigger: 'blur'}
        ],
        image: [
          {required: true, message: '请上传图片', trigger: 'blur'}
        ],
        flag: [
          {required: true, message: '请输入标识', trigger: 'blur'}
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      roleList: [],
      trajectory: [
        {
          "id":1,
          "name":"初级五点图"
        },
        {
          "id":2,
          "name":"初级8字图"
        },
        {
          "id":3,
          "name":"初级双三角图"
        },
        {
          "id":4,
          "name":"中级纵向图"
        },
        {
          "id":5,
          "name":"中级横向图"
        },
        {
          "id":6,
          "name":"初级双菱图"
        },
        {
          "id":7,
          "name":"高级方形图"
        },
        {
          "id":8,
          "name":"高级彩球图"
        },
        {
          "id":9,
          "name":"高级多角图"
        }
      ],
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {
    //this.queryRoles(); // 查询角色列表
  },
  methods: {
    // 上传成功
    handleAvatarSuccess(res, file) {
      // this.$set(this.form, 'image', process.env.VUE_APP_BASE_URL + res.data.path)
      this.$set(this.form, 'image', res.data.path)
    },
    before_handleAvatarSuccess(res, file) {
      // this.$set(this.form, 'image', process.env.VUE_APP_BASE_URL + res.data.path)
      this.$set(this.form, 'before_image', res.data.path)
    },
    // 上传之前的回调
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      // const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error('上传图片只能是 JPG 格式!');
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传图片大小不能超过 2MB!');
      // }
      // return isJPG && isLt2M;
    },
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http[this.isUpdate ? 'put' : 'post'](
                  this.isUpdate
                  ? "/Admin/background/" + this.form.id
                  : "/Admin/background", this.form).then(res => {
            this.loading = false;
            if (res.data.code === 200) {
              this.$message({type: 'success', message: res.data.msg});
              if (!this.isUpdate) {
                this.form = {};
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    }
    /*queryRoles() {
      this.$http
              .get("/Admin/level")
              .then((res) => {
                if (res.data.code === 200) {
                  this.roleList = res.data.data;
                  //console.log(this.roleList)
                } else {
                  this.$message.error(res.data.msg);
                }
              })
              .catch((e) => {
                this.$message.error(e.message);
              });
    },*/
  }
}
</script>

<style scoped>
</style>
