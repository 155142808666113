var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ele-body"},[_c('el-card',{attrs:{"shadow":"never"}},[_c('ele-pro-table',{ref:"table",attrs:{"needPage":false,"datasource":_vm.url,"columns":_vm.columns,"response":_vm.response,"selection":_vm.selection},on:{"update:selection":function($event){_vm.selection=$event}},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var row = ref.row;
return [_c('el-avatar',{staticStyle:{"width":"auto","height":"auto"},attrs:{"shape":"square","src":_vm.$baseImgUrl + row.image}})]}},{key:"before_image",fn:function(ref){
var row = ref.row;
return [_c('el-avatar',{staticStyle:{"width":"auto","height":"auto"},attrs:{"shape":"square","src":_vm.$baseImgUrl + row.before_image}})]}},{key:"action",fn:function(ref){
var row = ref.row;
return [(row.role_id !== 1)?_c('div',[_c('el-link',{attrs:{"icon":"el-icon-edit","type":"primary","underline":false},on:{"click":function($event){return _vm.openEdit(row)}}},[_vm._v("修改 ")]),_c('el-popconfirm',{staticClass:"ele-action",attrs:{"title":"确定要删除吗？"},on:{"confirm":function($event){return _vm.remove(row)}}},[_c('el-link',{attrs:{"slot":"reference","icon":"el-icon-delete","type":"danger","underline":false},slot:"reference"},[_vm._v("删除 ")])],1)],1):_vm._e()]}},{key:"roles",fn:function(ref){
var row = ref.row;
return _vm._l((row.current),function(item){return _c('el-tag',{key:item.id,attrs:{"type":"primary","size":"mini"}},[_vm._v(" "+_vm._s(item.name)+" ")])})}}])},[_c('template',{slot:"toolbar"},[_c('el-button',{staticClass:"ele-btn-icon",attrs:{"type":"primary","icon":"el-icon-plus","size":"small"},on:{"click":function($event){return _vm.openEdit(null)}}},[_vm._v("添加 ")]),_c('el-button',{staticClass:"ele-btn-icon",attrs:{"type":"danger","icon":"el-icon-delete","size":"small"},on:{"click":_vm.removeBatch}},[_vm._v("删除 ")])],1)],2)],1),_c('role-edit',{attrs:{"visible":_vm.showEdit,"data":_vm.current},on:{"update:visible":function($event){_vm.showEdit=$event},"done":_vm.reload}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }